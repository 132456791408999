<template>
  <b-card class="blog-edit-wrapper">
    <!-- media -->
    <b-media no-body vertical-align="center">
      <b-media-aside class="mr-75">
        <b-avatar size="38" :src="userData ? userData.avatar : 'null'" />
      </b-media-aside>
      <b-media-body>
        <h6 class="mb-25">
          {{ userData ? userData.fullName : "null" }}
        </h6>
        <b-card-text>{{ userData ? userData.email : "null" }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group label="Title" label-for="blog-edit-title" class="mb-2">
            <b-form-input id="blog-edit-title" v-model="blogCreate.title" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Category"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="blogCreate.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryOption"
              :reduce="(categoryOption) => categoryOption.id"
            />
          </b-form-group>
        </b-col>
        <!--<b-col md="6">
          <b-form-group label="Slug" label-for="blog-edit-slug" class="mb-2">
            <b-form-input id="blog-edit-slug" v-model="blogCreate.slug" />
          </b-form-group>
        </b-col>-->
        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="blogCreate.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
              :reduce="(statusOption) => statusOption.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Content" label-for="blog-content" class="mb-2">
            <quill-editor
              id="blog-content"
              v-model="blogCreate.content"
              :options="snowOption"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2">
          <div class="border rounded p-2">
            <h4 class="mb-1">Featured Image</h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="blogCreate.featuredImage"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted"
                  >Required image resolution 800x400, image size 10mb.</small
                >
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ blogFile ? blogFile.name : "banner.jpg" }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="blogFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
        <b-col cols="12" class="mt-50">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="submitBlog"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>
<script>
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import { mapGetters, mapActions } from "vuex";
import axiosIns from "../../../libs/axios";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blogCreate: {},
      blogFile: null,
      categoryOption: [],
      statusOption: [
        {
          label: "Draft",
          value: "draft",
        },
        {
          label: "Published",
          value: "published",
        },
      ],
      snowOption: {
        theme: "snow",
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  created() {
    axiosIns
      .get(`/api/category`)
      .then((res) => {
        this.categoryOption = res.data.data.categories.map((category) => {
          return {
            label: category.name,
            id: category.id,
          };
        });
        console.log(this.categoryOption);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    ...mapActions({
      createBlog: "blog/createBlog",
    }),
    submitBlog() {
      const formData = new FormData();
      formData.append("title", this.blogCreate.title);
      formData.append("category_id", this.blogCreate.category_id);
      formData.append("status", this.blogCreate.status);
      formData.append("content", this.blogCreate.content);
      formData.append("image", this.blogFile);

      this.createBlog(formData).then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success menambahkan blog`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `${this.blogCreate.title} berhasil ditambahkan`,
            },
          });
          this.blogCreate = {};
      }).catch((err) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Gagal menambahkan blog`,
            icon: "CoffeeIcon",
            variant: "error",
            text: `${this.blogCreate.title} gagal ditambahkan`,
          },
        });
      });
    },
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
